<template>
  <modal titulo="Detalhes DAJE">
    <small-loading :loading="true" v-if="loading" class="py-4"></small-loading>
    <card-content v-if="!loading">
      <table class="table table-striped card-table table-vcenter">
        <thead>
          <tr>
            <th class="pl-2">Emissor</th>
            <th class="pl-2">Valor</th>
            <th class="pl-2">Status</th>
            <th class="pl-2">Geração</th>
            <th class="pl-2">Vencimento</th>
            <th class="pl-2">Pagamento</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="'ficha_indisponibilidade'+index">
            <td class="pr-2">{{item.contribuinte}}
              <div class="w-100 mt-1 small text-muted">
                Documento: {{ item.cpfcnpj }}
              </div>
            </td>
            <td class="pr-2">{{$formatters.currency(item.valorDaj, 2, '-')}}</td>
            <td class="pr-2">{{item.status}}</td>
            <td class="pr-2">{{item.dataEmissao | data}}</td>
            <td class="pr-2">{{item.dataVencimento | data}}</td>
            <td class="pr-2">{{item.dataPagamento | data}}</td>
          </tr>
        </tbody>
      </table>
    </card-content>

  </modal>
</template>

<script>
  import darfBusiness from "@/business/DarfBusiness.js";
  import FrontBusiness from "@/business/FrontBusiness";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import Modal from "@/components/Modal";
  import SmallLoading from "@/components/SmallLoading.vue";

  export default {
    name: "ModalDetalhesDaje",
    components: {SmallLoading, Modal, CardContent},
    props: {
      guia : String,
    },
    data() {
      return {
        items : [],
        loading : true
      }
    },

    async mounted() {
      this.items = await this.$api.get(`/api/v2/regras-estaduais/BA/daje/${this.guia}`)
        .catch(e => {
          FrontBusiness.showError('Erro ao tentar consultar a Número da Guia', e?.message || e);
          this.close();
        });
      this.loading = false;
    },

    computed:{

    },

    methods:{

      close(resultado) {
        FrontBusiness.closeModal(resultado);
      },

    }
  }
</script>

<style>

</style>
